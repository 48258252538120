import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { STRAPI_URL } from '../../../config';

const ReactMarkdownComponent = ({ content }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdowncontent: file(relativePath: { regex: "/fullstory_bg.png/" }) {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1442) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      `}
      render={({ markdowncontent }) => (
        <BackgroundImage
          Tag="section"
          id="markdowncontent "
          fluid={markdowncontent.childImageSharp.fluid}
          className="h-full bg-bglight lg:bg-transparent xxl:bg-bglight my-12"
        >
          <div className="container mx-auto relative py-6 px-4 lg:py-40 xl:py-64 xxl:py-10">
            <ReactMarkdown
              className="lg:max-w-4xl mx-auto rich-text"
              transformImageUri={(uri) =>
                uri.startsWith('http') ? uri : `${STRAPI_URL}${uri}`
              }
              source={content}
            />
          </div>
        </BackgroundImage>
      )}
    />
  );
};

export default ReactMarkdownComponent;
