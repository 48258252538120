/* eslint-disable camelcase */
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { Employee } from '../components/common';
import ReactMarkdownComponent from '../components/traxers/react-markdown-component';
import TraxersList from '../components/traxers/traxers-list';

const StoryTemplate = ({ pageContext }) => {
  const {
    title,
    lead,
    content,
    employee,
    thumbnail,
    updated_at,
  } = pageContext.page;

  return (
    <section className="mt-16">
      <div className="container lg:max-w-5xl px-2">
        <div className="pt-10 lg:flex">
          <div className="lg:w-1/2">
            <h2>{title}</h2>
            <div className="flex py-2">
              <div className="w-1/2">
                <Employee employee={employee} />
              </div>
              <div className="w-1/2">
                <p className="text-sm font-light text-right">{updated_at}</p>
              </div>
            </div>
            <p className="lg:max-w-4xl mt-12 mb-6 font-medium">{lead}</p>
          </div>
          <div className="lg:w-1/2">
            <Img
              className="rounded-md lg:max-w-md lg:ml-auto"
              fluid={thumbnail.childImageSharp.fluid}
              alt="story"
            />
          </div>
        </div>
      </div>
      <ReactMarkdownComponent content={content} />
      <div className="container px-2">
        <div className="border-orangepast border-b mb-5 flex flex-wrap">
          <h3 className="text-left mr-auto">MORE STORIES</h3>
          <div className="flex">
            <Link
              to="/pioneering-traxers"
              className="text-orangepast border-b border-orangepast"
            >
              VIEW ALL STORIES
            </Link>
            <img
              src="/icons/arrow_orange.svg"
              width="20"
              className="inline pl-2 pb-1"
              alt="10 things we love most about Trax"
            />
          </div>
        </div>
        <TraxersList traxers={pageContext.more} />
      </div>
    </section>
  );
};

export default StoryTemplate;
